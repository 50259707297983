<template>
  <div class="mb-4">
    <div class="d-flex">      
      <h1 class="mr-auto" v-if="(title != '')">{{ title }}</h1>
      <div class="d-flex align-center">
        <slot name="action-rigth"></slot>
      </div>
    </div>
    <v-breadcrumbs class="mx-0 px-0 py-2" :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        href="javascript:;"
        @click="goTo(item.href)"
        :disabled="item.disabled"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    breadcrumbsItems: Array,
  },

  methods:{
    breadcrumbGo(href){
      this.goTo(href)
    }
  }
}
</script>


<style>
.v-breadcrumbs li, .v-breadcrumbs li a.v-breadcrumbs__item {
  font-size: 12px !important;
}
</style>