import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    redirect: 'dashboard',
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/search/Search.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/campaign',
    name: 'Campaign',
    component: () => import('@/views/campaign/Campaign.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/campaign/detail/:no_',
    name: 'CampaignDetail',
    component: () => import('@/views/campaign/CampaignDetail.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  // {
  //   path: '/campaign/detail/:no_/order-confirmation',
  //   name: 'campaigndetailorderconfirmation',
  //   component: () => import('@/views/campaign/detail/OrderConfirmationList.vue'),
  // },
  // {
  //   path: '/campaign/detail/:no_/delivery-preparation',
  //   name: 'campaigndetaildeliverypreparation',
  //   component: () => import('@/views/campaign/detail/DeliveryPreparationList.vue'),
  // },
  // {
  //   path: '/campaign/detail/:no_/on-delivery',
  //   name: 'campaigndetailondelivery',
  //   component: () => import('@/views/campaign/detail/OnDeliveryList.vue'),
  // },
  // {
  //   path: '/campaign/detail/:no_/received-by-customer',
  //   name: 'campaigndetailreceivedbycustomer',
  //   component: () => import('@/views/campaign/detail/ReceivedByCustomerList.vue'),
  // },
  // {
  //   path: '/campaign/detail/:no_/returned-by-customer',
  //   name: 'campaigndetailreturnedbycustomer',
  //   component: () => import('@/views/campaign/detail/ReturnedByCustomerList.vue'),
  // },
  // {
  //   path: '/campaign/detail/:no_/claim-process',
  //   name: 'campaigndetailclaimprocess',
  //   component: () => import('@/views/campaign/detail/ClaimProcessList.vue'),
  // },
  // {
  //   path: '/campaign/detail/:no_/invoicing',
  //   name: 'campaigndetailinvoicing',
  //   component: () => import('@/views/campaign/detail/InvoicingList.vue'),
  // },
  // {
  //   path: '/campaign/detail/:no_/payment',
  //   name: 'campaigndetailpayment',
  //   component: () => import('@/views/campaign/detail/PaymentList.vue'),
  // },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('@/views/orders/Orders.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/delivery',
    name: 'Delivery',
    component: () => import('@/views/delivery/Delivery.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/invoice',
    name: 'Invoice',
    component: () => import('@/views/invoice/Invoice.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/invoice/detail',
    name: 'InvoiceDetail',
    component: () => import('@/views/invoice/InvoiceDetail.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('@/views/payment/Payment.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  // {
  //   path: '/items-on-site',
  //   name: 'Itemsonsite',
  //   component: () => import('@/views/items-on-site/ItemsOnSite.vue'),
  // },
  {
    path: '/products',
    name: 'Products',
    component: () => import('@/views/products/Products.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/complaint',
    name: 'Complaint',
    component: () => import('@/views/complaint/Complaint.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/complaint/ticket/create',
    name: 'ComplaintTicketCreate',
    component: () => import('@/views/complaint/ticket/Create.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/complaint/ticket/detail',
    name: 'ComplaintTicketDetail',
    component: () => import('@/views/complaint/ticket/Detail.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/chat-us',
    name: 'ChatUs',
    component: () => import('@/views/chat-us/ChatUs.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/account/Account.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/notification',
    name: 'Notification',
    component: () => import('@/views/notification/index.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  // {
  //   path: '/typography',
  //   name: 'Typography',
  //   component: () => import('@/views/typography/Typography.vue'),
  // },
  // {
  //   path: '/icons',
  //   name: 'icons',
  //   component: () => import('@/views/icons/Icons.vue'),
  // },
  // {
  //   path: '/cards',
  //   name: 'cards',
  //   component: () => import('@/views/cards/Card.vue'),
  // },
  // {
  //   path: '/simple-table',
  //   name: 'simple-table',
  //   component: () => import('@/views/simple-table/SimpleTable.vue'),
  // },
  // {
  //   path: '/form-layouts',
  //   name: 'form-layouts',
  //   component: () => import('@/views/form-layouts/FormLayouts.vue'),
  // },
  // {
  //   path: '/pages/account-settings',
  //   name: 'pages-account-settings',
  //   component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  // },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      requiredLogin: false,
      layout: 'blank',
    },
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import('@/views/Forgot.vue'),
    meta: {
      requiredLogin: false,
      layout: 'blank',
    },
  },
  // {
  //   path: '/pages/register',
  //   name: 'pages-register',
  //   component: () => import('@/views/pages/Register.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('@/views/faq/Faq.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/error-404',
    name: 'Error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      requiredLogin: false,
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/test-page',
    name: 'TestPage',
    component: () => import('@/views/TestPage.vue'),
    meta: {
      requiredLogin: false,
      layout: 'none',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiredLogin == true) {
    let isLoggedIn = localStorage.getItem('PORTAL_STORAGE_isLoggedIn')

    if (isLoggedIn == 'true') {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
