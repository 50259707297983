// /* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-trailing-spaces */
/* eslint-disable arrow-body-style */
/* eslint-disable newline-before-return */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
/* eslint-disable arrow-parens */
"use strict";


import Vue from 'vue'
import axios from 'axios'
import AxiosStorage from 'axios-storage';

import config2 from '@/constants/config'

// set global config
AxiosStorage.config({
  storagePrefix: '_portalStorage_',
  storageMode: 'sessionStorage',
  deleteOnExpire: 'aggressive',
  maxAge: 2 * 60 * 1000
});

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = '';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
  adapter: AxiosStorage.adapter,
  baseURL: config2.apiUrl,

}

// eslint-disable-next-line no-underscore-dangle
const _axios = axios.create(config)

_axios.interceptors.request.use(
  // eslint-disable-next-line arrow-parens
  // eslint-disable-next-line no-shadow
  (config) => {
    // Do something before request is sent
    
    var apiToken = localStorage.getItem('PORTAL_STORAGE_apiToken')
    if(apiToken != ''){
      config.headers.Authorization = 'Bearer ' + apiToken
    }

    // let exceptPath = ['login', 'forgot'];

    // let pathname = window.location.pathname
    // for(var i = 0; i<exceptPath.length;i++){
    //   console.log(pathname);
    //   console.log(pathname.includes(exceptPath[i]));
    //   if(!pathname.includes(exceptPath[i])){
    //     var isLoggedIn = localStorage.getItem('PORTAL_STORAGE_isLoggedIn')
    //     if(isLoggedIn !== 'true'){
    //       localStorage.setItem('PORTAL_STORAGE_apiToken', '')
    //       localStorage.setItem('PORTAL_STORAGE_isLoggedIn', '')
    //       window.location.href = "/login";
    //     }
    //   }
    // }


    // eslint-disable-next-line semi
    return config;
  },
  (error) => {
    // Do something with request error

    if(error.response.status == 401){
      window.location.replace("/401");
    }
    
    return Promise.reject(error)
  },
)

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    // Do something with response data

    return response
  },
  (error) => {
    // Do something with response error
    if(error.response.status == 401){
      window.location.href = "/login";
    }

    return Promise.reject(error)
  },
)
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-shadow
Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      },
    },
    $axios: {
      get() {
        return _axios
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
