<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
    <PageOverlay v-if="$store.state.showPageOverlay" />
    <LoadingDialog  v-if="$store.state.showLoadingDialog"></LoadingDialog>
  </v-app>
</template>

<script>
import PageOverlay from '../components/PageOverlay.vue'
import LoadingDialog from '../components/LoadingDialog.vue'

export default {
  components: {
    PageOverlay,
    LoadingDialog,
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Ubuntu');

#awn-toast-container *, #awn-popup-wrapper {
  font-family: 'Ubuntu' !important;
}

</style>
