export default {
  data: () => ({
    loginInfo: {
      get username() {
        return localStorage.getItem('PORTAL_STORAGE_username');
      },
      set username(value) {
        localStorage.setItem('PORTAL_STORAGE_username', value);
      },
      get firstName() {
        return localStorage.getItem('PORTAL_STORAGE_firstName');
      },
      set firstName(value) {
        localStorage.setItem('PORTAL_STORAGE_firstName', value);
      },
      get lastName() {
        return localStorage.getItem('PORTAL_STORAGE_lastName');
      },
      set lastName(value) {
        localStorage.setItem('PORTAL_STORAGE_lastName', value);
      },
      get email() {
        return localStorage.getItem('PORTAL_STORAGE_email');
      },
      set email(value) {
        localStorage.setItem('PORTAL_STORAGE_email', value);
      },
      get profileImage() {
        return localStorage.getItem('PORTAL_STORAGE_profileImage');
      },
      set profileImage(value) {
        localStorage.setItem('PORTAL_STORAGE_profileImage', value);
      },
    },
  }),
  methods: {
    saveApiToken(token) {
      localStorage.setItem('PORTAL_STORAGE_apiToken', token);
      return localStorage.getItem('PORTAL_STORAGE_apiToken');
    },
    deleteApiToken() {
      localStorage.setItem('PORTAL_STORAGE_apiToken', '');
    },
    getLoginInfo() {
      var api_token = localStorage.getItem("PORTAL_STORAGE_apiToken");
      if (api_token == "undefined") {
        this.$store.state.isLoggedIn = false;
        return null;
      } else {
        this.apiGet("login/info")
          .then(res => {

            if (res.data.status == "success") {
              localStorage.setItem('PORTAL_STORAGE_username', res.data.data.username);
              localStorage.setItem('PORTAL_STORAGE_firstName', res.data.data.first_name);
              localStorage.setItem('PORTAL_STORAGE_lastName', res.data.data.last_name);
              localStorage.setItem('PORTAL_STORAGE_profileImage', res.data.data.user_img);

              // console.log(res.data.data);

              return res.data.data;
            } else {
              localStorage.setItem('PORTAL_STORAGE_username', '');
              localStorage.setItem('PORTAL_STORAGE_firstName', '');
              localStorage.setItem('PORTAL_STORAGE_lastName', '');
              localStorage.setItem('PORTAL_STORAGE_profileImage', '');

              this.$store.state.isLoggedIn = false;
              return null;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(err => {
            // console.log(err);
            localStorage.setItem('PORTAL_STORAGE_username', '');
            localStorage.setItem('PORTAL_STORAGE_firstName', '');
            localStorage.setItem('PORTAL_STORAGE_lastName', '');
            localStorage.setItem('PORTAL_STORAGE_profileImage', '');


            this.$store.state.isLoggedIn = false;
            return this.$store.state.loginInfo.null;
          });
      }
    },
    requiredLogin() {
      if (this.$store.state.isLoggedIn == false){
        this.$router.push("/login");
      }
    },
    signOut() {
      let onOk = () => {
        this.$store.state.apiToken = "";
        this.$store.state.loginInfo.username = "";
        this.$store.state.loginInfo.firstName = "";
        this.$store.state.loginInfo.lastName = "";
        this.$store.state.loginInfo.profileImage = "";
        this.$store.state.isLoggedIn = false;
        this.$router.push("/login");
      };
      let onCancel = () => {
        null
      };
      this.$awn.confirm(
        'Are you sure?',
        onOk,
        onCancel,
        {
          labels: {
            confirm: 'Confirm Logout'
          }
        }
      )
    },
  },
};