import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

import '@/plugins/axios'
import VueAWN from "vue-awesome-notifications"

import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);

import loginMixin from './mixins/loginMixin'
import helperMixin from './mixins/helperMixin'

import VueMeta from 'vue-meta'
import Vue2Editor from "vue2-editor";
// import VueQuillEditor from 'vue-quill-editor'
// // require styles
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

Vue.config.productionTip = false

Vue.mixin(loginMixin)
Vue.mixin(helperMixin)

Vue.use(VueAWN)
Vue.use(VueMoment, {
  moment,
})


Vue.use(VueMeta)
Vue.use(Vue2Editor)
// Vue.use(VueQuillEditor)

import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);


import appLocalStorageMixin from './mixins/appLocalStorageMixin'
Vue.mixin(appLocalStorageMixin);


// Component Register
// import InvoiceDue from './components/invoice/InvoiceDue'
// Vue.component('invoice-due', InvoiceDue)

import PageTitle from './components/PageTitle.vue';
Vue.component('page-title', PageTitle)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
