<template>
  <v-app id="inspire">
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen">
      <template slot="button">
        <v-fab-transition>
          <v-btn color="primary" fab dark small fixed bottom right class="d-block me-2" @click="isDrawerOpen = !isDrawerOpen">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
    </vertical-nav-menu>

    <v-app-bar app flat fixed color elevation="1">
      <!-- <v-app-bar app flat absolute color="transparent"> -->
      <div class="boxed-container w-full" v-if="!containerFluid">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <!-- <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon> -->

          <v-app-bar-nav-icon class="d-block me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <!-- <v-app-bar-title v-if="!isMobileBrowser() || ($vuetify.breakpoint.name !== 'sm' && $vuetify.breakpoint.name !== 'xs')">{{ $store.state.company.name }}</v-app-bar-title> -->
          <!-- <template slot:extension>
            <div style="widht:36px;" class="mr-4">
              <v-img :src="require('@/assets/images/logo.png')"  @click="isDrawerOpen = !isDrawerOpen" height="32" width="32" contain class=""></v-img>
            </div>
          </template>-->
          <template slot:extension>
            <v-text-field v-model="searchBox" @click:append="search" v-on:keyup.enter="search" class="app-bar-search flex-grow-0" filled rounded outlined hide-details dense append-icon="mdi-magnify" clearable style="max-width:300px" placeholder="Search Campaign, Invoice, etc"></v-text-field>

            <!-- <v-btn></v-btn> -->
          </template>
          <v-spacer></v-spacer>
          <!-- <v-spacer></v-spacer> -->

          <!-- Right Content -->
          <v-divider v-if="!isMobileBrowser() || ($vuetify.breakpoint.name !== 'sm' && $vuetify.breakpoint.name !== 'xs')" vertical class="ml-2 mr-3"></v-divider>
          <theme-switcher></theme-switcher>
          <v-btn icon small class="ms-3">
            <v-icon>{{ icons.mdiBellOutline }}</v-icon>
          </v-btn>
          <!-- <v-divider vertical class="ml-2 mr-3"></v-divider> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>

      <v-container fluid v-if="containerFluid">
        <v-row>
          <v-col class="d-flex align-center justify-start">
            <v-app-bar-nav-icon class="d-block me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
            <!-- <v-img :src="require('@/assets/images/logo.png')" height="32" width="32" contain class @click="isDrawerOpen = !isDrawerOpen"></v-img> -->
            <v-text-field v-model="searchBox" @click:append="search" v-on:keyup.enter="search" class="app-bar-search flex-grow-0" filled rounded outlined hide-details dense append-icon="mdi-magnify" clearable style="max-width:300px" placeholder="Search Campaign, Invoice, etc"></v-text-field>
          </v-col>
          <v-col class="d-flex align-center justify-center" v-if="!isMobileBrowser()">
            <v-img :src="require('@/assets/images/logo.png')" height="32" width="32" contain class></v-img>
          </v-col>
          <v-col class="d-flex align-center justify-end">
            <theme-switcher></theme-switcher>
            <notification></notification>
            <!-- <v-btn icon small class="ms-3">
              <v-icon>{{ icons.mdiBellOutline }}</v-icon>
            </v-btn> -->
            <v-divider v-if="!isMobileBrowser() || ($vuetify.breakpoint.name !== 'sm' && $vuetify.breakpoint.name !== 'xs')" vertical class="ms-2"></v-divider>
            <!-- <v-divider vertical class="ml-2 mr-3"></v-divider> -->
            <app-bar-user-menu></app-bar-user-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6" v-if="!containerFluid">
        <slot></slot>
      </div>
      <v-container fluid v-if="containerFluid">
        <slot></slot>
      </v-container>
      <!-- <v-btn color="primary" fab small dark >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>-->
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; {{ $store.state.company.copyright }}
            <a :href="$store.state.company.website" class="text-decoration-none" target="_blank">
              {{
              $store.state.company.name
              }}
            </a>
          </span>
          <span class="d-sm-inline d-none">
            <a :href="$store.state.company.website + 'product'" target="_blank" class="me-6 text--secondary text-decoration-none">Products</a>
            <a :href="$store.state.company.website + 'project'" target="_blank" class="me-6 text--secondary text-decoration-none">Projects</a>
            <a :href="$store.state.company.website + 'about-us'" target="_blank" class="text--secondary text-decoration-none">About Us</a>
          </span>
        </div>
      </div>
    </v-footer>

    <PageOverlay v-if="$store.state.showPageOverlay" />
    <LoadingDialog v-if="$store.state.showLoadingDialog"></LoadingDialog>
    <AppNotification v-if="$store.state.showAppNotification"></AppNotification>

    <v-fab-transition v-if="!isMobileBrowser()">
      <v-btn color="primary" fab dark small fixed bottom left class="d-block me-2" v-if="$store.state.showBackButton === true" v-show="!isDrawerOpen" @click="isDrawerOpen = !isDrawerOpen">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub, mdiArrowLeftCircle } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import Notification from './components/Notification.vue'
import PageOverlay from '../components/PageOverlay.vue'
import LoadingDialog from '../components/LoadingDialog.vue'
import AppNotification from '../components/AppNotification.vue'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    PageOverlay,
    LoadingDialog,
    AppNotification,
    Notification,
  },

  methods: {
    updateSearchText() {
      this.$store.state.searchText = this.searchBox
    },
    search() {
      this.$store.state.searchText = this.searchBox
      var q = {
        search: this.searchBox,
        token: new Date().valueOf(),
      }
      window.location.replace('/search?search='+q.search+'&token='+q.token)
    },
  },

  mounted() {
    this.searchBox = (this.$store.state.searchText) ? this.$store.state.searchText : this.$route.query.search
  },

  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      searchBox: '',

      containerFluid: true,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiArrowLeftCircle,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Ubuntu');
// @import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

// #app {
//   font-family: 'Comfortaa', cursive;
// }

.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.theme--light .v-main {
  // background-color: #fff !important;
  background-color: #f4f4f4 !important;
}
</style>
