import moment from 'moment';
import angkaTerbilang from '@develoka/angka-terbilang-js';
import AxiosStorage from 'axios-storage';
// import config from '@/constants/config';

export default {
  data: () => ({}),
  methods: {
    numberFormat(number, decimals, dec_point, thousands_sep) {
      if (typeof number === 'undefined' || isNaN(number) == true) {
        return 0
      }
      // Strip all characters but numerical ones.
      number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
        dec = typeof dec_point === 'undefined' ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
          var k = Math.pow(10, prec)
          return '' + Math.round(n * k) / k
        }
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
      }
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1).join('0')
      }
      return s.join(dec)
    },
    isMobileBrowser() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        if(this.$vuetify.breakpoint.name == 'sm' || this.$vuetify.breakpoint.name == 'xs')
        {
          return true;
        }
        return false;
      }
    },
    setNavDrawerSetOp() {
      var t = this.$store.state.navDrawer

      if (t == true) {
        this.$store.state.navDrawer = false
      } else {
        this.$store.state.navDrawer = true
      }
    },
    ucFirst(string) {
      if (typeof string !== 'undefined') return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
      return ''
    },
    ucFirstAll(string) {
      if (typeof string == 'undefined') return ''

      var str = ''
      string.toLowerCase()

      const myArr = string.split(' ')
      if (typeof myArr !== 'undefined') {
        myArr.forEach(function (item) {
          if (typeof item !== 'undefined') str = str + ' ' + item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
        })
      }

      return str
    },
    appNotification(message = '', type_ = '') {
      this.$store.state.showAppNotification = true
      this.$store.state.msgAppNotification = message
      this.$store.state.typeAppNotification = type_
    },
    asyncLoading(promise) {
      this.$store.state.showLoadingDialog = true
      return promise.then((this.$store.state.showLoadingDialog = false))
    },
    numberToShortText(number) {
      if (number < 1000) {
        return this.numberFormat(number)
      }

      if (number >= 1000 && number < 1000000) {
        return this.numberFormat(number / 1000) + 'K'
      }

      if (number >= 1000000) {
        return this.numberFormat(number / 1000000) + 'M'
      }
    },
    goTo(uri) {
      // console.log(uri);
      return this.$router.push(uri)
    },
    goToExternalUrl(uri, newWindow=false) {
      if(newWindow){
        window.open(uri)
      }else{
        window.location.href = uri
      }
      return true;
    },
    goBack(){
      this.$router.back()
    },
    redirect(routerName, query){
      return this.$router.push({ name: routerName, query: query });
    },
    arrayUniqBy(a, f) {
      var rows = []
      var tmpF = []
      a.forEach(item => {
        if (tmpF.includes(item[f]) == false) {
          rows.push(item)
        }

        tmpF.push(item)
      })

      return tmpF
    },
    showAlert(type = '', message = '') {
      if (type == 'success') {
        this.$awn.success(message)
      } else if (type == 'info') {
        this.$awn.info(message)
      } else if (type == 'warning') {
        this.$awn.warning(message)
      } else if (type == 'error') {
        this.$awn.alert(message)
      } else {
        this.$awn.info(message)
      }
    },
    appDateFormat(date) {
      var _date = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
      if (_date === 'Invalid date') return ''
      return _date
    },
    terbilang(angka, rd=2) {
      angka = Number(angka).toFixed(rd)
      return angkaTerbilang(angka)
    },
    // setLocalData(key, data, expired){
    //   try {
    //     if(Array.isArray(data)){
    //       var d = {
    //         key: key,
    //         data: data,
    //         expired: expired,
    //       }

    //       var dJson = JSON.stringify(d);

    //       localStorage.setItem("WOT_LOCAL_DATA_" + key, dJson);

    //       return true;
    //     } else {
    //       var d2 = {
    //         key: key,
    //         data: data,
    //         expired: expired,
    //       }

    //       localStorage.setItem("WOT_LOCAL_DATA_" + key, JSON.stringify(d2))

    //       return true;
    //     }
    //   } catch (e) {
    //     console.log(e.toString());
    //     return false;
    //   }
    // },
    // getLocalData(key){
    //   var tmpLocalData = localStorage.getItem("WOT_LOCAL_DATA_" + key);
    //   console.log(tmpLocalData);
    //   try {
    //     var objTmpLocalData = JSON.parse(tmpLocalData);
        
    //     if(Array.isArray(objTmpLocalData)){
    //       return objTmpLocalData.data;
    //     }
    //     return null;
    //   } catch (e)
    //   {
    //     console.log(e.toString());
    //     return null;
    //   }
    // },
    showLoadingDialog(s){
      this.$store.state.showLoadingDialog = s
    },
    requiredLogin(){
      if (this.$store.state.isLoggedIn == false) {
        this.$router.push('/login')
      }
    },
    setTitle(title){
      document.title = title
      this.$store.state.pageTitle = title
    },
    showLoadingOverlay(val){
      this.$emit("showLoadingOverlay", val);
    },

    AxiosStorageRemove(method, key){
      let oCache = AxiosStorage.getCache('sessionStorage');
      var storageKey = method + '.' + key;
      oCache.remove(storageKey);
    },
    AxiosStorageRemoveAll(){
      let oCache = AxiosStorage.getCache('sessionStorage');
      oCache.removeAll();
    },
    formatDate(stringdate){
      if(stringdate == '') return stringdate
      const d = new Date(stringdate);
      const month = d.toLocaleString('default', { month: 'long' });
      let a = month + ', ' + d.getDate() + ' ' + d.getFullYear();
      return a
    }
  },
}
