<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" style="color:#ddd" fixed :mini-variant.sync="mini">
      <div style="margin-top:62px">
        <v-list>
          <nav-menu-link title="Dashboard" :icon="icons.mdiHomeOutline"></nav-menu-link>
          <v-divider class="my-2"></v-divider>
          <nav-menu-link title="Campaign" :icon="icons.mdiCube"></nav-menu-link>
          <v-divider class="my-2"></v-divider>
          <nav-menu-link title="Products" :icon="icons.mdiCubeScan"></nav-menu-link>
          <nav-menu-link title="Compaint" :icon="icons.mdiCommentQuestionOutline"></nav-menu-link>
          <nav-menu-link title="Chat with Us" :icon="icons.mdiChat"></nav-menu-link>
          <v-divider class="my-2"></v-divider>
          <nav-menu-link title="FAQ" :to="{ name: 'Faq' }" :icon="icons.mdiHelpCircleOutline"></nav-menu-link>
        </v-list>
      </div>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-row>
        <v-col class="d-flex align-center">
          <v-app-bar-nav-icon @click.stop="drawer=!drawer"></v-app-bar-nav-icon>
          <template slot:extension>
            <v-text-field class="app-bar-search flex-grow-0" filled rounded outlined hide-details dense append-icon="mdi-magnify" clearable style="max-width:300px" placeholder="Search Campaign, Invoice, etc"></v-text-field>
          </template>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <div style="widht:36px;" class="mr-4">
            <v-img :src="require('@/assets/images/logo.png')" @click="isDrawerOpen = !isDrawerOpen" height="32" width="32" contain class></v-img>
          </div>
          <span>PT. Beton Perkasa Wijaksana</span>
        </v-col>
        <v-col class="d-flex align-center">
          <v-spacer></v-spacer>
          <v-btn icon class="mx-2">
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn>
          <v-divider vertical class="mx-2"></v-divider>
          <v-avatar color="primary">
            <v-img :src="require('@/assets/images/logo.png')" @click="isDrawerOpen = !isDrawerOpen" height="32" width="32" contain class></v-img>
          </v-avatar>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row>
          <v-col v-for="n in 24" :key="n" cols="4">
            <v-card height="200"></v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiCube,
  mdiTruckCheck,
  mdiShopping,
  mdiChat,
  mdiCash,
  mdiTruckFast,
  mdiCreditCardSettingsOutline,
  mdiCommentQuestionOutline,
  mdiHelpCircleOutline,
  mdiCubeScan,
} from '@mdi/js'
import NavMenuLink from '@/layouts/components/vertical-nav-menu/components/NavMenuLink.vue'

export default {
  components: {
    NavMenuLink,
  },
  data() {
    return {
      drawer: null,
      mini: null,
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiCube,
        mdiTruckCheck,
        mdiShopping,
        mdiChat,
        mdiCash,
        mdiTruckFast,
        mdiCreditCardSettingsOutline,
        mdiCommentQuestionOutline,
        mdiHelpCircleOutline,
        mdiCubeScan,
      },
    }
  },
}
</script>