<template>
  <component :is="resolveLayout">
    <router-view @showLoadingOverlay="showLoadingOverlay"></router-view>
    <v-overlay :value="overlay" z-index="999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import LayoutNone from '@/layouts/None.vue'

export default {
  metaInfo: {
    title: "Customer Portal",
  },
  components: {
    LayoutBlank,
    LayoutContent,
    LayoutNone,
  },
  data() {
    return {
      overlay: false,
    };
  },
  watch:{
      // eslint-disable-next-line no-unused-vars
      '$route' (to, from){
          // Put your logic here...
          // console.log(to);
          // console.log(from);

          this.$store.state.loadNotification = true
      }
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'none') return 'layout-none'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>

<style>
@import '~vue-awesome-notifications/dist/styles/style.css';

/* @import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap");
@font-face {
  font-family: "Comfortaa", cursive;
  src: url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap");
}
* {
  font-family: "Comfortaa", cursive !important;
} */
</style>