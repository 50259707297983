import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    get apiToken() {
      return localStorage.getItem('PORTAL_STORAGE_apiToken');
    },
    set apiToken(value) {
      localStorage.setItem('PORTAL_STORAGE_apiToken', value);
    },
    get isLoggedIn() {
      if (localStorage.getItem('PORTAL_STORAGE_isLoggedIn') == 'true'){
        return true;
      }else{
        return false;
      }
    },
    set isLoggedIn(value) {
      localStorage.setItem('PORTAL_STORAGE_isLoggedIn', value);
    },
    get localData() {
      return localStorage.getItem('PORTAL_STORAGE_local_data')
    },
    set localData(value) {
      localStorage.setItem('PORTAL_STORAGE_local_data', value);
    },
    loginInfo: {
      get username() {
        return localStorage.getItem('PORTAL_STORAGE_username');
      },
      set username(value) {
        localStorage.setItem('PORTAL_STORAGE_username', value);
      },
      get firstName() {
        return localStorage.getItem('PORTAL_STORAGE_firstName');
      },
      set firstName(value) {
        localStorage.setItem('PORTAL_STORAGE_firstName', value);
      },
      get lastName() {
        return localStorage.getItem('PORTAL_STORAGE_lastName');
      },
      set lastName(value) {
        localStorage.setItem('PORTAL_STORAGE_lastName', value);
      },
      get email() {
        return localStorage.getItem('PORTAL_STORAGE_email');
      },
      set email(value) {
        localStorage.setItem('PORTAL_STORAGE_email', value);
      },
      get profileImage() {
        return localStorage.getItem('PORTAL_STORAGE_profileImage');
      },
      set profileImage(value) {
        localStorage.setItem('PORTAL_STORAGE_profileImage', value);
      },
    }, 
    pageTitle: '',
    showBackButton: false,
    showNavBar: true,
    showToolbar: true,
    showPageOverlay: false,
    showLoadingDialog: false,
    showAppNotification: false,
    msgAppNotification: '',
    typeAppNotification: '',
    navDrawer: null,
    pageInfo: {},
    snackbarAlert: {
      show: false,
      msg: ""
    },
    appTheme:{
      toolbarDark: true,
      toolbarColor: 'purple darken-4',
    },
    // get navDrawer() {
    //   var l = localStorage.getItem('navDrawer')
    //   if (l == 'yes') {
    //     return true;
    //   }
    //   return false;
    // },
    // set navDrawer(value) {
    //   if (value == true){
    //     localStorage.setItem('PORTAL_STORAGE_navDrawer', 'yes');
    //   }else{
    //     localStorage.setItem('PORTAL_STORAGE_navDrawer', 'no');        
    //   }
    // },
    company: {
      name: 'PT. Beton Perkasa Wijaksana',
      shortName: 'BETON',
      website: 'https://www.betonperkasa.com/',
      copyright: '2021'
    },
    searchText:"",
    loadNotification: true,
  },
  mutations: {},
  actions: {},
  modules: {}
})