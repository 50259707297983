<template>
  <v-menu offset-y left nudge-bottom="14" max-width="300">
    <template v-slot:activator="{ on, attrs }">
      <div v-if="showBadge">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-btn icon class="ms-3" v-bind="attrs" v-on="on">
            <v-icon>{{ icons.mdiBellOutline }}</v-icon>
          </v-btn>
        </v-badge>
      </div>
      <div v-else>
        <v-btn icon class="ms-3" v-bind="attrs" v-on="on">
          <v-icon>{{ icons.mdiBellOutline }}</v-icon>
        </v-btn>
      </div>
    </template>
    <v-card :loading="loading">
      <v-list max-width="300" three-line style="max-height:600px;overflow-y:auto">
        <v-list-item v-for="(item, i) in notifications" :key="i" @click="gotoNotificationUrl(item)" style="height:auto" color="primary" :class="(item.readed==1) ? 'readed' : ''">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle style="font-size:12px">
              <div v-html="item.content"></div>
            </v-list-item-subtitle>
            <v-list-item-subtitle style="font-size:12px" class="text-right">{{ getOldtime(item.notif_date) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>

        <!-- View All -->
        <v-list-item>
          <v-btn block text @click="redirect('Notification')">View All</v-btn>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiBellOutline,
} from '@mdi/js'

export default {
  data: () => ({
    notifications: [],
    icons: {
      mdiAccountOutline,
      mdiEmailOutline,
      mdiCheckboxMarkedOutline,
      mdiChatOutline,
      mdiCogOutline,
      mdiCurrencyUsd,
      mdiHelpCircleOutline,
      mdiLogoutVariant,
      mdiBellOutline,
    },
    loading: null,
  }),

  computed : {
    showBadge(){
      if(this.notifications.length < 1){
        return false;
      }
      
      let a = 0;
      for(var i = 0; i < this.notifications.length; i++){
        if(this.notifications[i].readed == 0){
          a++;
        }
      }

      if(a > 0) return true;

      return false;
    }
  },

  watch: {

  },

  methods: {
    async getData() {
      // this.loading = true
      // this.notifications = []
      await this.$axios
        .get('notification/data')
        .then(response => {
          this.notifications = response.data.data

          // console.log(response.data.data);
          
          // this.loading = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
          // this.loading = false
        })
        .then(() => {
          // this.loading = false
        })
    },

    runLoader(){
      if(this.$store.state.loadNotification == true){
        this.getData();
        this.$store.state.loadNotification = false
        setTimeout(()=>{
          this.runLoader()
        }, 1000)
      }
    },

    getOldtime(t) {
      var DateDiff = {
        inSecond: function (d1, d2) {
          var t2 = d2.getTime()
          var t1 = d1.getTime()

          return parseInt((t2 - t1) / (1000))
        },

        inMinutes: function (d1, d2) {
          var t2 = d2.getTime()
          var t1 = d1.getTime()

          return parseInt((t2 - t1) / (60 * 1000))
        },

        inHours: function (d1, d2) {
          var t2 = d2.getTime()
          var t1 = d1.getTime()

          return parseInt((t2 - t1) / (3600 * 1000))
        },

        inDays: function (d1, d2) {
          var t2 = d2.getTime()
          var t1 = d1.getTime()

          return parseInt((t2 - t1) / (24 * 3600 * 1000))
        },

        inWeeks: function (d1, d2) {
          var t2 = d2.getTime()
          var t1 = d1.getTime()

          return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7))
        },

        inMonths: function (d1, d2) {
          var d1Y = d1.getFullYear()
          var d2Y = d2.getFullYear()
          var d1M = d1.getMonth()
          var d2M = d2.getMonth()

          return d2M + 12 * d2Y - (d1M + 12 * d1Y)
        },

        inYears: function (d1, d2) {
          return d2.getFullYear() - d1.getFullYear()
        },
      }
      var d1 = new Date(t)
      var d2 = new Date()


      if(DateDiff.inSecond(d1, d2) <= 30){
        return 'just now'
      }else if(DateDiff.inSecond(d1, d2) > 30 && DateDiff.inSecond(d1, d2) <= 59){
        return DateDiff.inSecond(d1, d2).toString() + ' seconds ago'
      }else if(DateDiff.inMinutes(d1, d2) <= 59){
        return DateDiff.inMinutes(d1, d2).toString() + ' minutes ago'
      }else if(DateDiff.inHours(d1, d2) <= 24){
        return DateDiff.inHours(d1, d2).toString() + ' hours ago'
      }else if(DateDiff.inDays(d1, d2) <= 30){
        return DateDiff.inDays(d1, d2).toString() + ' days ago'
      }else if(DateDiff.inMonths(d1, d2) <= 12){
        return DateDiff.inMonths(d1, d2).toString() + ' months ago'
      }else if(DateDiff.inYears(d1, d2) <= 12){
        return DateDiff.inYears(d1, d2).toString() + ' years ago'
      }


      // console.log(DateDiff.inMinutes(d1, d2));
      return DateDiff.inMinutes(d1, d2);
    },

    setRead(id){
      this.$axios
        .get('notification/read', {
          params: {
            id: id
          }
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response);
          this.getData()
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
        })
        .then(() => {
          this.loading = false
        })
    },

    gotoNotificationUrl(notifData){
      this.setRead(notifData.id)
      this.goToExternalUrl(notifData.redirect_url)
    },
  },

  mounted() {
    this.getData()
    this.runLoader()
  },
}
</script>

<style lang="scss">
.readed{
  background-color: #f2f2f2;
}
</style>
