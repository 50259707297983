<template>
  <div class="text-center ma-2">
    <v-snackbar
      v-model="$store.state.showAppNotification"
      :color="$store.state.typeAppNotification"
      :timeout="timeout"
    >
      {{ $store.state.msgAppNotification }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="$store.state.showAppNotification = false"
        >Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: 'AppNotification',
  data: () => ({
    timeout: 2000,
  }),
}
</script>