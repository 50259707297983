<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    width="260"
    :right="$vuetify.rtl"
    class="app-navigation-menu"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/images/logo-wide.png')"
          max-height="45px"
          max-width="148px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <!-- <v-slide-x-transition>
          <h2 class="app-title text--primary">Beton Perkasa <br>Wijaksana</h2>
        </v-slide-x-transition> -->
      </router-link>
    </div>

    <!-- <v-divider class="mt-2"></v-divider> -->

    <!-- Navigation Items -->
    <!-- <v-list dense shaped class="vertical-nav-menu-items pr-5"> -->
    <v-list dense class="vertical-nav-menu-items">
      <nav-menu-link title="Dashboard" :to="{ name: 'Dashboard' }" :icon="icons.mdiHomeOutline"></nav-menu-link>
      <v-divider class="my-2"></v-divider>
      <!-- <nav-menu-link title="Campaign" :to="{ name: 'Campaign' }" :icon="icons.mdiCube"></nav-menu-link>
      <nav-menu-link title="Orders" :to="{ name: 'Orders' }" :icon="icons.mdiShopping"></nav-menu-link>
      <nav-menu-link title="Delivery" :to="{ name: 'Delivery' }" :icon="icons.mdiTruckFast"></nav-menu-link>
      <nav-menu-link title="Invoices" :to="{ name: 'Invoice' }" :icon="icons.mdiCash"></nav-menu-link>
      <nav-menu-link title="Payment" :to="{ name: 'Payment' }" :icon="icons.mdiCreditCardSettingsOutline"></nav-menu-link>
      <v-divider class="my-2"></v-divider> -->
      <nav-menu-link title="Campaign" :to="{ name: 'Campaign' }" :icon="icons.mdiCube"></nav-menu-link>
      <v-divider class="my-2"></v-divider>
      <nav-menu-link title="Products" :to="{ name: 'Products' }" :icon="icons.mdiCubeScan"></nav-menu-link>
      <nav-menu-link title="Compaint" :to="{ name: 'Complaint' }" :icon="icons.mdiCommentQuestionOutline"></nav-menu-link>
      <nav-menu-link title="Chat with Us" :to="{ name: 'ChatUs' }" :icon="icons.mdiChat"></nav-menu-link>
      <v-divider class="my-2"></v-divider>
      <nav-menu-link title="FAQ" :to="{ name: 'Faq' }" :icon="icons.mdiHelpCircleOutline"></nav-menu-link>
    </v-list>
    
    <slot name="button"></slot>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiCube,
  mdiTruckCheck,
  mdiShopping,
  mdiChat,
  mdiCash,
  mdiTruckFast,
  mdiCreditCardSettingsOutline,
  mdiCommentQuestionOutline,
  mdiHelpCircleOutline,
  mdiCubeScan,
} from '@mdi/js'
// import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
// import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    // NavMenuSectionTitle,
    // NavMenuGroup,
    NavMenuLink,
  },
  computed:{
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiCube,
        mdiTruckCheck,
        mdiShopping,
        mdiChat,
        mdiCash,
        mdiTruckFast,
        mdiCreditCardSettingsOutline,
        mdiCommentQuestionOutline,
        mdiHelpCircleOutline,
        mdiCubeScan,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
