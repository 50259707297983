require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#9155FD',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
      dark: {
        primary: '#9155FD',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
    },
  },
}


// export default {
//   theme: {
//     themes: {
//       light: {
//         primary: '#ee161f',
//         accent: '#ee6716',
//         secondary: '#767b84',
//         success: '#56ca00',
//         info: '#373737',
//         warning: '#ffb400',
//         error: '#db0006',
//       },
//       dark: {
//         primary: '#8d7cff',
//         accent: '#fd0d0d',
//         secondary: '#8A8D93',
//         success: '#56CA00',
//         info: '#16B1FF',
//         warning: '#FFB400',
//         error: '#FF4C51',
//       },
//     },
//   },
// }
